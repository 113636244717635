import React from "react"
import { useTranslation } from "react-i18next"
import { Grid, Typography, Paper } from "@material-ui/core"
import { getUser } from "../../services/auth/auth"
import { Table } from "../../components/table"
import SEO from "../../components/seo"
import axios from "axios"
import { useQuery as useQueryRest } from "react-query"
import {
  MusicNote,
  People,
  InsertEmoticon,
  SvgIconComponent,
} from "@material-ui/icons"

const getPerformanceSummary = () => {
  const { token } = getUser()
  return axios.get(`${process.env.GATSBY_API_URL}/performances/summary`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

const SummaryCard = ({
  label,
  number,
  color,
  Icon,
}: {
  label: string
  Icon: SvgIconComponent
  number: number
  color: string
}) => {
  return (
    <Paper>
      <Grid container>
        <Grid
          container
          item
          style={{ background: color }}
          justifyContent="center"
          alignItems="center"
          xs={4}
        >
          <Icon fontSize="large" style={{ color: "white" }} />
        </Grid>
        <Grid item xs={8} style={{ padding: "10px" }}>
          <Typography align="center" variant="h3">
            {number}
          </Typography>
          <Typography align="center" noWrap variant="h4">
            {" "}
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export const ViewStatistics = () => {
  const { data: summaryResponse, isLoading } = useQueryRest(
    "performanceSummary",
    getPerformanceSummary
  )
  const { t } = useTranslation()
  const totalsByCountry = summaryResponse?.data.totalsByCountry
  const totalsByCity = summaryResponse?.data.totalsByCity
  return (
    <Grid container direction="column" spacing={2}>
      <SEO title={t("page.admin.statistics")} />
      <Grid item container spacing={2}>
        {[
          {
            label: t("admin.performance-count"),
            number: totalsByCountry?.performanceCount,
            color: "#FFCC33",
            Icon: MusicNote,
          },

          {
            label: t("admin.performer-count"),
            number: totalsByCountry?.performerCount,
            color: "#006A44",
            Icon: InsertEmoticon,
          },
          {
            label: t("admin.member-count"),
            number: totalsByCountry?.memberCount,
            color: "#C1272D",
            Icon: People,
          },
        ].map(summary => (
          <Grid item xs={4}>
            <SummaryCard {...summary} />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Table
          isLoading={isLoading}
          title={t("admin.city-statistics")}
          data={totalsByCity}
          options={{
            paging: false,
            search: false,
          }}
          columns={[
            {
              title: t("admin.city"),
              field: "cityName",
            },
            {
              title: t("admin.performance-count"),
              field: "performanceCount",
            },
            {
              title: t("admin.performer-count"),
              field: "performerCount",
            },
            {
              title: t("admin.member-count"),
              field: "memberCount",
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}
