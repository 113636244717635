import React, { useEffect, useState } from "react"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import { object, string } from "yup"
import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  Typography,
  Snackbar,
  MenuItem,
} from "@material-ui/core"
import { Map, TileLayer, Marker, Viewport } from "react-leaflet"
import { useTranslation } from "react-i18next"
import { LeafletMouseEvent, LatLngExpression } from "leaflet"

import SEO from "components/seo"
import { TextField } from "components/text-field"
import { CitySelection, useQueryVisibleCities } from "./common/city-selector"
import { getUser, getUserToken, isAdmin } from "services/auth/auth"
import { coordinateStringToLatLngLiteral } from "utilities"
import { Table } from "components/table"
import { City, Zone } from "types"

import { Form } from "../final-form/form"
import { Field } from "../final-form/field"

import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import { FormRenderProps } from "react-final-form"
import { SelectField } from "components/select-field"
import axios, { AxiosPromise } from "axios"

interface Location {
  id: number
  name: string
  city: City
  zone: {
    id: any
    name: string
  }
  coordinates: string
  description: string
}

const getLocationPerformances = ({
  locationId,
}: {
  locationId: string
}): AxiosPromise<{
  performances: Array<Performance>
}> => {
  return axios.get(
    `${process.env.GATSBY_API_URL}/locations/${locationId}/performances`,
    {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    }
  )
}

const DeleteConfirmationDialog = (props: {
  isOpen: boolean
  locationId: string
  onCancel: () => void
  onConfirm: () => void
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const { isOpen, locationId, onCancel, onConfirm, onClose } = props
  const [locationPerformances, setLocationPerformances] = useState([])
  useEffect(() => {
    if (isOpen) {
      getLocationPerformances({ locationId }).then(
        ({ data: { performances } }) => {
          setLocationPerformances(performances)
        }
      )
    }
  }, [isOpen, locationId])
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          {locationPerformances.length > 0
            ? t("admin.location-delete-confirmation-with-performances", {
                performanceCount: locationPerformances.length,
              })
            : t("admin.location-delete-confirmation")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          {t("button.no")}
        </Button>
        <Button
          onClick={() => {
            onConfirm()
            onClose()
          }}
          color="secondary"
          autoFocus
        >
          {t("button.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const LocationEditForm = (
  props: {
    submitButtonLabel: string
    onDeleteLocation: () => void
    zones: Zone
  } & FormRenderProps
) => {
  const { t } = useTranslation()
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            name="name"
            label={t("admin.name")}
            component={TextField}
            fullWidth
            autoFocus
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="description"
            label={t("admin.description")}
            component={TextField}
            fullWidth
            autoFocus
            InputProps={{
              rows: 7,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="zoneId"
            label={t("admin.zone")}
            component={SelectField}
            fullWidth
            autoFocus
          >
            <MenuItem value="" />
            {props.zones &&
              props.zones.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Field>
        </Grid>

        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              onClick={props.onDeleteLocation}
              variant="outlined"
              color="secondary"
            >
              {t("button.delete")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={props.submitting || !props.dirty}
              variant="outlined"
              color="secondary"
              type="submit"
            >
              {props.submitButtonLabel}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

const MapMarkersView = (props: {
  cityCenterCoordinates: string
  pendingLocation: string
  onSelectLocation: (id: number) => void
  onCreateLocation: (latLngStringified: string) => void
  selectedLocationId: number
  savedLocations?: {
    id: number
    coordinates: string
  }[]
}) => {
  const {
    cityCenterCoordinates,
    onSelectLocation,
    onCreateLocation,
    selectedLocationId,
    savedLocations = [], // Locations returned from the DB
    pendingLocation, // Adding of a new location
  } = props
  const [viewport, setViewport] = useState<Viewport>({
    zoom: 10,
    center: coordinateStringToLatLngLiteral(cityCenterCoordinates),
  })
  return (
    <Map
      style={{
        height: "600px",
        width: "100%",
      }}
      onViewportChanged={(viewport: Viewport) => {
        setViewport(viewport)
      }}
      viewport={viewport}
      onClick={(event: LeafletMouseEvent) => {
        const { latlng } = event
        const latLngStringified = `${latlng.lat},${latlng.lng}`
        onCreateLocation(latLngStringified)
      }}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {savedLocations.map(({ id, coordinates }) => {
        return (
          <Marker
            key={id}
            onClick={onSelectLocation.bind(null, id)}
            opacity={id === selectedLocationId ? 1 : 0.5}
            position={coordinateStringToLatLngLiteral(coordinates)}
          />
        )
      })}
      {pendingLocation && (
        <Marker position={coordinateStringToLatLngLiteral(pendingLocation)} />
      )}
    </Map>
  )
}

interface Zone {
  id: any
  is_acoustic: boolean
  city: any
}

export const EditLocations = () => {
  const { cityId: userCityId } = getUser()
  const [currentCity, setCurrentCity] = useState(userCityId)
  const { data: cityData } = useQueryVisibleCities()
  const { data: zoneData, refetch: refetchZoneData } = useQuery<{
    cities: Zone[]
  }>(FIND_ZONES, { variables: { city: currentCity } })
  const currentCityData =
    cityData &&
    cityData.cities.find(
      //@ts-ignore
      ({ id }) => parseInt(id as unknown, 10) === parseInt(currentCity, 10)
    ) //TODO: find out why id is returned as a string instead of number

  const { t } = useTranslation()
  const [createLocation] = useMutation(CREATE_LOCATION)
  const [updateLocation] = useMutation(UPDATE_LOCATION)
  const [deleteLocation] = useMutation(DELETE_LOCATION)
  const { data: locationData, refetch } = useQuery<{ locations: Location[] }>(
    FIND_LOCATIONS,
    {
      variables: {
        city: currentCity || userCityId,
      },
    }
  )
  const [pendingLocation, setPendingLocation] = useState()
  const [toastConfirmation, setToastConfirmation] = useState()
  const [selectedLocationId, setSelectedLocation] = useState()
  const [isDeleteConfirmationOpen, setDeleteConfirmation] = useState(false)
  const [lastSelectedZoneId, setLastSelectedZoneId] = useState() // Useful when adding multiple locations across the same zone
  const selectedLocationData =
    locationData &&
    locationData.locations.find(({ id }) => id === selectedLocationId)
  const emptyInitialValues = {
    name: "",
    description: "",
    zoneId: lastSelectedZoneId,
  }
  const savedLocations =
    locationData &&
    locationData.locations.map(({ id, coordinates }) => ({ id, coordinates }))
  const deleteLocationAfterConfirmation = async () => {
    if (selectedLocationData) {
      await deleteLocation({ variables: { id: selectedLocationData.id } })
      setToastConfirmation(t("admin.location-deleted-toast"))
      refetch()
    } else {
      setPendingLocation("")
    }
  }

  const onSubmitLocation = async (props: {
    name: string
    description: string
    zoneId: number
  }) => {
    const { name, description, zoneId } = props
    if (selectedLocationId) {
      await updateLocation({
        variables: {
          id: selectedLocationId,
          name,
          coordinates: selectedLocationData && selectedLocationData.coordinates,
          city: selectedLocationData && selectedLocationData.city.id,
          description,
          zone: zoneId,
        },
      })
      setToastConfirmation(t("admin.location-updated-toast"))
    } else {
      await createLocation({
        variables: {
          name,
          description,
          coordinates: pendingLocation,
          city: currentCityData && currentCityData.id,
          zone: zoneId,
        },
      })
      setLastSelectedZoneId(zoneId)
      setToastConfirmation(t("admin.location-created-toast"))
    }
    refetch()
    setPendingLocation(null)
    setSelectedLocation(null)
  }

  const isNoLocationSelected = !(selectedLocationData || pendingLocation)

  const initialValues = pendingLocation
    ? emptyInitialValues
    : {
        name: selectedLocationData && selectedLocationData.name,
        description:
          (selectedLocationData && selectedLocationData.description) || "",
        zoneId:
          selectedLocationData &&
          selectedLocationData.zone &&
          selectedLocationData.zone.id,
      }

  const onSelectMapLocation = (id: number) => {
    setPendingLocation(null)
    setSelectedLocation(id)
  }
  const confirmLocationDeletion = () => {
    setDeleteConfirmation(true)
  }
  const onChooseNewLocationOnMap = (latLngStringified: string) => {
    setSelectedLocation(null)
    setPendingLocation(latLngStringified)
  }
  const validationSchema = object().shape({
    name: string().required(),
    zoneId: string().required(),
    description: string(),
  })
  const [createZone] = useMutation(CREATE_ZONE)
  const [updateZone] = useMutation(UPDATE_ZONE)
  const [deleteZone] = useMutation(DELETE_ZONE)
  return (
    <Grid container spacing={2} direction="column">
      <SEO title={t("page.admin.locations")} />
      {isAdmin() && (
        <Grid item>
          <CitySelection
            cities={cityData && cityData.cities}
            selectedCity={currentCity}
            onSelectCity={(cityId: number | string) => {
              setCurrentCity(cityId)
            }}
          />
        </Grid>
      )}
      {currentCityData && (
        <Grid item container spacing={4}>
          <Grid item xs={12}>
            <Table
              title={t("admin.zones")}
              addItemLabel={t("admin.add-zone")}
              columns={[
                { title: t("admin.name"), field: "name" },
                {
                  title: t("admin.is-acoustic"),
                  field: "isAcoustic",
                  type: "boolean",
                },
              ]}
              // @ts-ignore
              data={
                zoneData &&
                zoneData.zones.map(({ id, is_acoustic, name }) => ({
                  id,
                  name,
                  isAcoustic: is_acoustic,
                }))
              }
              editable={{
                onRowAdd: async ({ name, isAcoustic }) => {
                  await createZone({
                    variables: {
                      name,
                      isAcoustic: !!isAcoustic,
                      city: currentCity,
                    },
                  })
                  refetchZoneData()
                },
                onRowUpdate: async ({ id, name, isAcoustic, city }) => {
                  await updateZone({
                    variables: { id, name, isAcoustic, city: currentCity },
                  })
                  refetchZoneData()
                },
                onRowDelete: async ({ id }) => {
                  await deleteZone({ variables: { id } })
                  refetchZoneData()
                },
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Snackbar
              autoHideDuration={1000}
              open={!!toastConfirmation}
              message={toastConfirmation}
              onClose={() => {
                setToastConfirmation("")
              }}
            />
            <DeleteConfirmationDialog
              onConfirm={deleteLocationAfterConfirmation}
              onCancel={setDeleteConfirmation.bind(null, false)}
              onClose={setDeleteConfirmation.bind(null, false)}
              locationId={selectedLocationData && selectedLocationData.id}
              isOpen={isDeleteConfirmationOpen}
            />
            <MapMarkersView
              cityCenterCoordinates={currentCityData.coordinates}
              pendingLocation={pendingLocation}
              selectedLocationId={selectedLocationId}
              onCreateLocation={onChooseNewLocationOnMap}
              onSelectLocation={onSelectMapLocation}
              savedLocations={savedLocations}
            />
          </Grid>
          <Grid item xs={4} container direction="column">
            <Typography variant="h3" gutterBottom>
              {selectedLocationData
                ? selectedLocationData.name
                : t("admin.new-location-header")}
            </Typography>
            {isNoLocationSelected ? (
              <Typography>{t("admin.choose-location-text")}</Typography>
            ) : (
              <Form
                // @ts-ignore
                onSubmit={onSubmitLocation}
                // @ts-ignore
                validationSchema={validationSchema}
                // @ts-ignore
                initialValues={initialValues}
                render={formProps => (
                  // @ts-ignore
                  <LocationEditForm
                    {...formProps}
                    zones={
                      zoneData &&
                      zoneData.zones.map(({ id, name }) => ({
                        id,
                        name,
                      }))
                    }
                    submitButtonLabel={
                      selectedLocationData
                        ? t("button.change")
                        : t("button.create")
                    }
                    onDeleteLocation={confirmLocationDeletion}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

const FIND_ZONES = gql`
  query zones($city: ID!) {
    zones(where: { city: $city }) {
      id
      name
      is_acoustic
    }
  }
`
const CREATE_ZONE = gql`
  mutation CreateZone($name: String!, $city: ID!, $isAcoustic: Boolean!) {
    createZone(
      input: { data: { name: $name, city: $city, is_acoustic: $isAcoustic } }
    ) {
      zone {
        name
      }
    }
  }
`

const UPDATE_ZONE = gql`
  mutation UpdateZone($id: ID!, $name: String!, $isAcoustic: Boolean!) {
    updateZone(
      input: {
        data: { name: $name, is_acoustic: $isAcoustic }
        where: { id: $id }
      }
    ) {
      zone {
        id
      }
    }
  }
`

const DELETE_ZONE = gql`
  mutation DeleteZone($id: ID!) {
    deleteZone(input: { where: { id: $id } }) {
      zone {
        id
      }
    }
  }
`

const FIND_LOCATIONS = gql`
  query locations($city: ID!) {
    locations(where: { city: $city }) {
      id
      name
      description
      coordinates
      city {
        name
        coordinates
      }
      zone {
        id
        name
      }
    }
  }
`

const CREATE_LOCATION = gql`
  mutation CreateLocation(
    $name: String!
    $coordinates: String!
    $city: ID!
    $description: String
    $zone: ID!
  ) {
    createLocation(
      input: {
        data: {
          name: $name
          coordinates: $coordinates
          city: $city
          description: $description
          zone: $zone
        }
      }
    ) {
      location {
        name
        coordinates
      }
    }
  }
`

const UPDATE_LOCATION = gql`
  mutation UpdateLocation(
    $id: ID!
    $name: String!
    $coordinates: String!
    $description: String
    $zone: ID!
  ) {
    updateLocation(
      input: {
        data: {
          name: $name
          coordinates: $coordinates
          description: $description
          zone: $zone
        }
        where: { id: $id }
      }
    ) {
      location {
        id
      }
    }
  }
`

const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(input: { where: { id: $id } }) {
      location {
        id
      }
    }
  }
`
