import React, { useEffect, useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import { useMutation } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import { Table } from "../../components/table"
import { useTranslation } from "react-i18next"
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core"
import SEO from "../../components/seo"
import {
  LocationPreviewModal,
  MarkerLocationPreview,
} from "../maps/marker-location-preview"
import { LeafletMouseEvent } from "leaflet"
import { City } from "types"
import {
  AddCircleOutlined,
  Error,
  RemoveCircleOutlined,
} from "@material-ui/icons"
import axios, { AxiosPromise } from "axios"
import { getUserToken } from "services/auth/auth"
import { red } from "@material-ui/core/colors"

const CITIES = gql`
  {
    cities {
      name
      id
      coordinates
      is_visible
      hourFrom
      hourTo
    }
  }
`

const CREATE_CITY = gql`
  mutation CreateCity(
    $name: String!
    $coordinates: String!
    $hourFrom: Float!
    $hourTo: Float!
    $is_visible: Boolean
  ) {
    createCity(
      input: {
        data: {
          name: $name
          coordinates: $coordinates
          hourFrom: $hourFrom
          hourTo: $hourTo
          is_visible: $is_visible
        }
      }
    ) {
      city {
        name
      }
    }
  }
`

const UPDATE_CITY = gql`
  mutation UpdateCity(
    $id: ID!
    $name: String!
    $coordinates: String!
    $hourFrom: Float!
    $hourTo: Float!
    $is_visible: Boolean
  ) {
    updateCity(
      input: {
        data: {
          name: $name
          coordinates: $coordinates
          hourFrom: $hourFrom
          hourTo: $hourTo
          is_visible: $is_visible
        }
        where: { id: $id }
      }
    ) {
      city {
        id
      }
    }
  }
`

const DELETE_CITY = gql`
  mutation DeleteCity($id: ID!) {
    deleteCity(input: { where: { id: $id } }) {
      city {
        id
      }
    }
  }
`

const decimalHourToSexagesimal = (decimalHour: number) => {
  const hours = Math.floor(decimalHour)
  const minutes = (decimalHour % 1) * 60
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`
}

const isTimeIntervalValid = (newTime: TimePeriod) => {
  return ![
    (time: TimePeriod) => {
      return time.hourFrom < 0 || time.hourTo < 0
    },
    (time: TimePeriod) => {
      return time.hourFrom > 24 || time.hourTo > 24
    },
    (time: TimePeriod) => {
      return time.hourTo - time.hourFrom > 24
    },
    (time: TimePeriod) => {
      return time.hourFrom > time.hourTo
    },
  ].some(predicate => predicate(newTime))
}
interface TimePeriod {
  hourFrom: number
  hourTo: number
}

const TimeDisplayComponent = ({ hourFrom, hourTo }: TimePeriod) => {
  return (
    <Box fontFamily="Monospace" fontSize="1.5rem">
      {`${decimalHourToSexagesimal(hourFrom)} - ${decimalHourToSexagesimal(
        hourTo
      )}`}
    </Box>
  )
}

const validateCityTime = ({
  cityId,
  hourFrom,
  hourTo,
}: {
  cityId: string
  hourTo: number
  hourFrom: number
}): AxiosPromise<{
  isCityTimeValid: boolean
}> => {
  return axios.get(
    `${process.env.GATSBY_API_URL}/cities/time-valid/${cityId}?hourFrom=${hourFrom}&hourTo=${hourTo}`,
    {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    }
  )
}

const TimeEditBox = ({
  time,
  label,
  isFlipped,
  onIncrease,
  onDecrease,
}: {
  time: number
  label: string
  isFlipped?: boolean
  onIncrease: () => void
  onDecrease: () => void
}) => {
  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      direction={`${isFlipped ? "row-reverse" : "row"}`}
    >
      <Grid item container direction="column">
        <IconButton
          onClick={() => {
            onIncrease()
          }}
        >
          <AddCircleOutlined />
        </IconButton>
        <IconButton
          onClick={() => {
            onDecrease()
          }}
        >
          <RemoveCircleOutlined />
        </IconButton>
      </Grid>
      <Grid item container alignContent="center">
        <Box p={1} display="flex" flexDirection="column" alignItems="center">
          <Chip
            size="small"
            label={
              <Box color="textSecondary" fontWeight="bold">
                {label}
              </Box>
            }
          />
          <Box fontFamily="Monospace" fontSize="2rem">
            {decimalHourToSexagesimal(time)}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

const TimeEditComponent = ({
  hourFrom,
  hourTo,
  onChange,
  isServerSideValid = true,
}: {
  hourFrom: number
  hourTo: number
  onChange: (time: TimePeriod) => void
  isServerSideValid: boolean
}) => {
  const { t } = useTranslation()
  const validate = (newTime: TimePeriod) => {
    return isTimeIntervalValid(newTime) ? newTime : { hourFrom, hourTo }
  }
  return (
    <Box position="relative">
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-around"
      >
        {!isServerSideValid && (
          <Box position="absolute" bottom={-20} margin="auto">
            <Chip
              icon={
                <Error
                  color="error"
                  style={{
                    fill: red[500],
                  }}
                />
              }
              size="small"
              label={
                <Box fontWeight="bold">
                  {t("admin.performance-overlap-error")}
                </Box>
              }
            />
          </Box>
        )}
        <TimeEditBox
          time={hourFrom}
          label={t("admin.from")}
          onIncrease={() => {
            onChange(validate({ hourFrom: hourFrom + 0.5, hourTo }))
          }}
          onDecrease={() => {
            onChange(validate({ hourFrom: hourFrom - 0.5, hourTo }))
          }}
        />
        <TimeEditBox
          isFlipped
          time={hourTo}
          label={t("admin.to")}
          onIncrease={() => {
            onChange(validate({ hourFrom, hourTo: hourTo + 0.5 }))
          }}
          onDecrease={() => {
            onChange(validate({ hourFrom, hourTo: hourTo - 0.5 }))
          }}
        />
      </Grid>
    </Box>
  )
}

export const EditCities = () => {
  const { t } = useTranslation()
  const { data, refetch } = useQuery(CITIES)
  const [createCity] = useMutation(CREATE_CITY)
  const [updateCity] = useMutation(UPDATE_CITY)
  const [deleteCity] = useMutation(DELETE_CITY)

  const tableData =
    data &&
    data.cities.map(
      ({ id, name, coordinates, is_visible, hourFrom, hourTo }: City) => ({
        id,
        name,
        coordinates,
        isVisible: is_visible,
        time: {
          hourFrom,
          hourTo,
        },
      })
    )
  return (
    <>
      <SEO title={t("page.admin.cities")} />
      <Table
        title={t("page.admin.cities")}
        addItemLabel={t("admin.add-city")}
        columns={[
          {
            title: t("admin.name"),
            field: "name",
          },
          { title: t("admin.is-visible"), field: "isVisible", type: "boolean" },
          {
            title: t("admin.time"),
            field: "time",
            render: ({ time }) => <TimeDisplayComponent {...time} />,
            editComponent: ({ value, onChange, rowData }) => {
              const hourFrom = value && value.hourFrom
              const hourTo = value && value.hourTo
              const [isServersideValid, setIsServersideValid] = useState<
                boolean
              >(true)
              useEffect(() => {
                if (!value) {
                  onChange({
                    hourFrom: 10,
                    hourTo: 22,
                  })
                }
                if (rowData.id) {
                  validateCityTime({
                    cityId: rowData.id,
                    hourFrom,
                    hourTo,
                  }).then(({ data }) => {
                    setIsServersideValid(data.isTimeValid)
                  })
                }
              }, [hourFrom, hourTo, rowData.id])
              return (
                <TimeEditComponent
                  isServerSideValid={isServersideValid}
                  hourFrom={hourFrom}
                  hourTo={hourTo}
                  onChange={onChange}
                />
              )
            },
          },
          {
            title: t("admin.coordinates"),
            field: "coordinates",
            render: ({ coordinates }) => {
              return (
                <LocationPreviewModal
                  name={t("admin.view")}
                  coordinates={coordinates}
                />
              )
            },
            editComponent: ({ value, onChange }) => {
              return (
                <LocationEditComponent
                  onChange={onChange}
                  center={
                    value || "55.2997256,23.9055102" /**Lithuania center**/
                  }
                  coordinates={value}
                />
              )
            },
          },
        ]}
        data={tableData}
        editable={{
          onRowAdd: async ({ name, coordinates, time, isVisible }) => {
            await createCity({
              variables: {
                name,
                coordinates,
                hourFrom: parseFloat(time.hourFrom),
                hourTo: parseFloat(time.hourTo),
                is_visible: isVisible,
              },
            })
            refetch()
          },
          onRowUpdate: async ({ id, name, coordinates, time, isVisible }) => {
            await updateCity({
              variables: {
                id,
                name,
                coordinates,
                hourFrom: parseFloat(time.hourFrom),
                hourTo: parseFloat(time.hourTo),
                is_visible: isVisible,
              },
            })
            refetch()
          },
          onRowDelete: async ({ id }) => {
            await deleteCity({ variables: { id } })
            refetch()
          },
        }}
      />
    </>
  )
}

const MapLocationModal = (props: {
  isMapSelectionOpen: boolean
  coordinates?: string
  center?: string
  onSelectLocation: (event: LeafletMouseEvent) => void
  onClose: () => void
  onConfirmLocation: () => void
}) => {
  const { t } = useTranslation()
  return (
    <Dialog open={props.isMapSelectionOpen}>
      <DialogTitle>{t("admin.change-location")}</DialogTitle>
      <MarkerLocationPreview
        center={props.center}
        coordinates={props.coordinates}
        onClick={props.onSelectLocation}
      />
      <DialogActions>
        <Button onClick={props.onClose} color="secondary">
          {t("button.close")}
        </Button>
        <Button onClick={props.onConfirmLocation} color="secondary">
          {t("button.save")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const LocationEditComponent = (props: {
  onChange: (coordinates: string) => void
  center: string
  coordinates?: string
}) => {
  const { t } = useTranslation()
  const [isMapSelectionOpen, setMapSelectionModalState] = useState(false)
  const closeModal = setMapSelectionModalState.bind(null, false)
  return (
    <>
      <MapLocationModal
        isMapSelectionOpen={isMapSelectionOpen}
        coordinates={props.coordinates}
        center={props.center}
        onClose={closeModal}
        onConfirmLocation={() => {
          setMapSelectionModalState(false)
          closeModal()
        }}
        onSelectLocation={({ latlng }) => {
          const latLngStringified = `${latlng.lat},${latlng.lng}`
          props.onChange(latLngStringified)
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setMapSelectionModalState(true)
        }}
      >
        {t("button.change")}
      </Button>
    </>
  )
}
