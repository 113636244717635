import React, { useState } from "react"
import { useQuery } from "react-query"
import clsx from "clsx"
import { MTableBodyRow } from "material-table"
import { useTranslation } from "react-i18next"
import { Check, Clear } from "@material-ui/icons"
import { Grid, makeStyles } from "@material-ui/core"
import axios from "axios"

import { getUser } from "services/auth/auth"
import SEO from "components/seo"
import { Table } from "components/table"
import { isValue } from "utilities/helpers"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  rowConfirmed: {
    background: theme.palette.success.light,
  },
  rowDeclined: {
    background: theme.palette.error.light,
  },
}))

enum AdminRequestStatus {
  NEW = "new",
  CONFIRMED = "confirmed",
  DECLINED = "declined",
}

const getAdminRequests = () => {
  const { token } = getUser()
  return axios.get(`${process.env.GATSBY_API_URL}/city-admin-requests`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

const confirmCityAdmin = (userId: number) => {
  const { token } = getUser()
  return axios.post(
    `${process.env.GATSBY_API_URL}/city-admin-requests/confirm`,
    {
      userId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const rejectCityAdmin = (userId: number) => {
  const { token } = getUser()
  return axios.post(
    `${process.env.GATSBY_API_URL}/city-admin-requests/reject`,
    {
      userId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}
interface User {
  id: number
  email: string
}

interface City {
  id: number
  name: string
}
interface CityAdminResponse {
  user: User
  city: City
  status: AdminRequestStatus
}

interface RowData {
  userId: string
  email: string
  cityName: string
  status: AdminRequestStatus
}

export const EditCityAdmins = (props: {}) => {
  const [isAdminConfirmSending, setIsAdminConfirmSending] = useState(false)
  const { t } = useTranslation()
  const classes = useStyles(props)
  const {
    data: adminResponse,
    refetch: refetchAdminRequests,
    isFetching,
  } = useQuery<{ data: CityAdminResponse[] }, {}>(
    "admin-requests",
    getAdminRequests
  )
  const filterUsers = ({ city }: CityAdminResponse) => isValue(city)
  const mapUsers = ({ user, status, city }: CityAdminResponse) => ({
    userId: user.id,
    email: user.email,
    cityName: city.name,
    status,
  })
  const tableData =
    adminResponse && adminResponse.data.filter(filterUsers).map(mapUsers)

  return (
    <Grid container direction="column" spacing={2}>
      <SEO title={t("page.admin.city-admins")} />
      <Grid item>
        <Table
          isLoading={isFetching || isAdminConfirmSending}
          title={t("page.admin.city-admins")}
          data={tableData || []}
          components={{
            Row: props => (
              <MTableBodyRow
                {...props}
                className={clsx({
                  [classes.rowConfirmed]:
                    props.data.status === AdminRequestStatus.CONFIRMED,
                  [classes.rowDeclined]:
                    props.data.status === AdminRequestStatus.DECLINED,
                })}
              />
            ),
          }}
          columns={[
            {
              title: t("register.email"),
              field: "email",
            },
            {
              title: t("admin.city"),
              field: "cityName",
            },

            {
              title: t("admin.status"),
              field: "status",
              render: (data: RowData) => {
                const translations = {
                  [AdminRequestStatus.NEW]: t("admin.new"),
                  [AdminRequestStatus.CONFIRMED]: t("admin.confirmed"),
                  [AdminRequestStatus.DECLINED]: t("admin.declined"),
                }
                return translations[data.status]
              },
            },
          ]}
          actions={[
            data => ({
              icon: () => <Check />,
              disabled: data.status === AdminRequestStatus.CONFIRMED,
              tooltip: t("admin.confirm-performance"),
              onClick: async (_, { userId }) => {
                setIsAdminConfirmSending(true)
                await confirmCityAdmin(userId)
                setIsAdminConfirmSending(false)
                refetchAdminRequests()
              },
            }),
            data => ({
              icon: () => <Clear />,
              disabled: data.status === AdminRequestStatus.DECLINED,
              tooltip: t("admin.decline-performance"),
              onClick: async (_, { userId }) => {
                setIsAdminConfirmSending(true)
                await rejectCityAdmin(userId)
                refetchAdminRequests()
                setIsAdminConfirmSending(false)
              },
            }),
          ]}
        />
      </Grid>
    </Grid>
  )
}
